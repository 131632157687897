import React, { useEffect, useState } from "react";
import Navbar from "../admin-navbar/Navbar";
import * as api from "../../../api/ApiRequest";
import { getFromStorage } from "../../utility/Storage";
import NotificationContainer from "../../utility/NotificationContainer";
import { showNotification } from "../../utility/showNotification";
import { clearNotifications } from "../../utility/clearNotifications";
import moment from "moment-timezone"
import "./adminusercreate.css"
import { useNavigate } from "react-router-dom";

export default function AdminUserCreate() {
    const navigate = useNavigate()

    const [permissionList, setPermissionList] = useState([]);
    const [permittedIds, setPermittedIds] = useState([]);
    const [userList, setUserList] = useState([]);
    const [userDetails, setUserDetails] = useState({
        username: "",
        password: "",
        role: 0,
    });

    useEffect(
        () => {
            fetchData();
        },
        // eslint-disable-next-line
        []
    );

    const fetchData = async () => {
        const res = await api.fetchPermissionList();
        setPermissionList(res?.list || []);
        
        const users = await api.fetchUsersList();
        setUserList(users?.list || []);
    };

    const handleUserInfo = (e) => {
		setUserDetails(prev => ({
			...prev,
			[e.target.name]: e.target.value
		}))
	};

    const handleCheckBox = (e) => {
        if (e.target.checked) {
            setPermittedIds((prev) => [...prev, parseInt(e.target.value)]);
        } else {
            setPermittedIds((prev) =>
                prev.filter((each) => each !== parseInt(e.target.value))
            );
        }
    };

    const handleRole = (e) => {
        setUserDetails(prev => ({
            ...prev,
            role: parseInt(e.target.value)
        }))
    }

    const handleSubmit = async () => {
        if(userDetails.username === ""){
            clearNotifications()
            return showNotification("error", "Username Field is required")
        }
        
        if(userDetails.password === ""){
            clearNotifications()
            return showNotification("error", "Password Field is required")
        }

		const user = getFromStorage("dnr_a")
		const payload = {
			...userDetails,
			permitted: permittedIds.join(","),
			created_by: user.id
		}

		const res = await api.userRegistration(payload)

		if(res.flag === 'SUCCESS'){
            const users = await api.fetchUsersList();
            setUserList(users?.list || []);

            clearNotifications()
			showNotification("success", res.msg)
		} else {
            clearNotifications()
			showNotification("error", res.msg)
		}
    };

    const getRole = (value) => {
        const role = {
            0: "-",
            1: "Admin",
            2: "Editor",
            3: "Reporter"
        }

        return role[value]
    }

    const getStatus = (value) => {
        const status = {
            0: "Deactive",
            1: "Active"
        }

        return status[value]
    }

    const getPermission = (value) => {
        const ids = value.split(",")

        const list = ids.map(eachId => {
            const permitted = permissionList.find(
                each => parseInt(each.id) === parseInt(eachId)).permission_name

            return permitted
        })

        if(list.length === permissionList.length){
            return "All Permitted"
        }
        
        return list.join(", ")
    }

    const getModifiedAt = (value) => {
        if(value === 0) return "-"

        return moment(value * 1000).tz("Asia/Dhaka").format("DD-MMM-YY hh:mm a")
    }

    const getModifiedBy = (id) => {
        return userList?.find(each => each?.id === id)?.username || "-"
    }

    const handleUserStatus = async (id, status) => {
        const user = getFromStorage("dnr_a")

        const payload = { 
            id, status,
            modified_by: user.id,
            modified_at: moment().tz("Asia/Dhaka").unix()
        }

        const res = await api.userStatusChange(payload)

        if(res?.flag === 'SUCCESS'){
            const users = await api.fetchUsersList();
            setUserList(users?.list || []);
            clearNotifications()
            return showNotification("success", res?.msg)    
        } else {
            clearNotifications()
            return showNotification("error", res?.msg)    
        }
    }

    return (
        <>
            <Navbar />
			<NotificationContainer />

            <div className="usercreate__container">
                <div>User Create</div>
                <div className="usercreate__input-group">
                    <input
                        type="text"
                        autoComplete="off"
                        placeholder="Username"
                        name="username"
                        onChange={handleUserInfo}
                    />
                    <input
                        type="text"
                        autoComplete="off"
                        placeholder="Password"
                        name="password"
                        onChange={handleUserInfo}
                    />
                    <select name="role" id="role" onChange={handleRole}>
                        <option value="0">Select Role</option>
                        <option value="1">Reporter</option>
                        <option value="2">Editor</option>
                        <option value="3">Admin</option>
                    </select>
                </div>
                <div className="usercreate__checkbox">
                    {permissionList.map((each) => (
                        <div key={each.permission_name}>
                            <input
                                type="checkbox"
                                id={each.id}
                                value={each.id}
                                onChange={handleCheckBox}
                            />
                            <label className="usercreate__checkbox-label" htmlFor={each.id}>{each.permission_name}</label>
                        </div>
                    ))}
                </div>
                <div>
                    <button className="usercreate__btn usercreate__btn-create" onClick={handleSubmit}>Create Account</button>
                </div>
            </div>

            <div className="userlist__title">User Lists</div>

            <div className="userlist__container">
                <table style={{ borderSpacing: "0 0.5rem" }}>
                    <thead>
                        <tr>
                            <th className="usercreate__table-head">Username</th>
                            <th className="usercreate__table-head">Role</th>
                            <th className="usercreate__table-head">Permissions</th>
                            <th className="usercreate__table-head">Status</th>
                            <th className="usercreate__table-head">Last Modified</th>
                            <th className="usercreate__table-head">Modified By</th>
                            <th className="usercreate__table-head">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userList.map(each => (
                            <tr key={each.username} className="usercreate__table-cell">
                                <td style={{ width: "20%" }}>{ each.username }</td>
                                <td style={{ width: "10%", textAlign: "center" }}>{ getRole(each.role) }</td>
                                <td style={{ width: "20%", textAlign: "center" }}>{ getPermission(each.permitted_actions) }</td>
                                <td style={{ width: "10%", textAlign: "center" }} className={`${each.status ? "usercreate__status-green" : "usercreate__status-red"}`}>{ getStatus(each.status) }</td>
                                <td style={{ width: "15%", textAlign: "center" }}>{ getModifiedAt(each.modified_at) }</td>
                                <td style={{ width: "10%", textAlign: "center" }}>{ getModifiedBy(each.modified_by) }</td>
                                <td style={{ width: "15%" }}>
                                    <div className="usercreate__btn-group">
                                        <button 
                                            className="usercreate__btn usercreate__btn-edit"
                                            onClick={() => navigate("/admin/user/edit", {
                                                state: each
                                            })}
                                        >
                                            Edit
                                        </button>
                                        {each.status 
                                            ?
                                            <button 
                                                className="usercreate__btn usercreate__btn-deactivate" 
                                                onClick={() => handleUserStatus(each.id, 0)}
                                            >
                                                Deactivate
                                            </button>
                                            :
                                            <button 
                                                className="usercreate__btn usercreate__btn-activate" 
                                                onClick={() => handleUserStatus(each.id, 1)}
                                            >
                                                Activate
                                            </button>
                                        }
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}	  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  