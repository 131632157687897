import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getFromStorage } from "../components/utility/Storage";

export default function AlreadyLoggedIn() {
    const user = getFromStorage("dnr_a")
    const errors = ["", undefined, null];

    if (!errors.includes(user) && !errors.includes(user?.auth)) {
        return <Navigate to="/admin/dashboard" />;
    } else {
        return <Outlet />;
    }
}
