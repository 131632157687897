import React from 'react'
import { Navigate } from 'react-router-dom'
import { checkPermission } from '../components/utility/checkPermission'

export default function CheckRoutePermission({route, children}) {
	const allowed = checkPermission(route)
  
	if(allowed){
		return children
	} else {
		return <Navigate to="/admin/dashboard" />
	}
}
