import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { checkPermission } from "../../utility/checkPermission";
import { getFromStorage } from "../../utility/Storage";
import "./navbar.css";

export default function Navbar() {
    const [username, setUsername] = useState("");
    const navigate = useNavigate();

    useEffect(
        () => {
            getUsername();
        },
        // eslint-disable-next-line
        []
    );

    const getUsername = () => {
        const user = getFromStorage("dnr_a");
        setUsername(user?.username?.toUpperCase() || "");
    };

    const handleLogout = () => {
        localStorage.removeItem("dnr_a");
        localStorage.removeItem("dnr_p");
        navigate("/admin");
    };

    return (
        <div className="navbar">
            <div className="navbar__header">
                <span
                    className="navbar__title"
                    onClick={() => navigate("/admin/dashboard")}
                >
                    DNR - Dhaka Newsroom |
                </span>
                <ul className="navbar__navigation">
                    {checkPermission("publish-news") && (
                        <NavLink
                            className="navbar__li"
                            to={"/admin/news/create"}
                        >
                            Publish News
                        </NavLink>
                    )}

                    {checkPermission("news-list") && (
                        <NavLink className="navbar__li" to={"/admin/news/list"}>
                            News List
                        </NavLink>
                    )}

                    {checkPermission("create-media") && (
                        <NavLink
                            className="navbar__li"
                            to={"/admin/media/create"}
                        >
                            Create Media
                        </NavLink>
                    )}

                    {checkPermission("scroll-publish") && (
                        <NavLink
                            className="navbar__li"
                            to={"/admin/news/scroll"}
                        >
                            Scroll Publish
                        </NavLink>
                    )}

                    {checkPermission("create-user") && (
                        <NavLink
                            className="navbar__li"
                            to={"/admin/user/create"}
                        >
                            Create User
                        </NavLink>
                    )}

                    <NavLink className="navbar__li" to={"/"}>
                        Home Page
                    </NavLink>
                </ul>
            </div>

            <div className="navbar__user">
                <div>{username}</div>
                <div>
                    <button
                        className="navbar__btn-logout"
                        onClick={handleLogout}
                    >
                        Log Out
                    </button>
                </div>
            </div>
        </div>
    );
}
