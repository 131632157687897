import { getFromStorage } from "./Storage"

export const checkPermission = (val) => {
	const user = getFromStorage("dnr_a")
	const permission = user?.permitted?.split(",")?.map(each => parseInt(each))
	
	const list = getFromStorage("dnr_p")
	const errors = ["", null, undefined]

	if(errors.includes(user?.permitted)) return false

	if(permission.includes(list[val])) return true

	return false
}