import React, { useEffect, useState } from "react";
import Navbar from "../admin-navbar/Navbar";
import * as api from "../../../api/ApiRequest";
import { getFromStorage } from "../../utility/Storage";
import NotificationContainer from "../../utility/NotificationContainer";
import { showNotification } from "../../utility/showNotification";
import { clearNotifications } from "../../utility/clearNotifications";
import "../admin-user-create/adminusercreate.css"
import { useLocation, useNavigate } from "react-router-dom";

export default function AdminUserEdit() {
	const navigate = useNavigate()
	const url = useLocation()

    const [permissionList, setPermissionList] = useState([]);
	const [alreadyPermittedIds, setAlreadyPermittedIds] = useState([]);
    const [userDetails, setUserDetails] = useState({
        username: "",
        password: "",
        role: 0,
    });

    useEffect(
        () => {
            fetchData();
        },
        // eslint-disable-next-line
        []
    );

    const fetchData = async () => {
        const res = await api.fetchPermissionList();
        setPermissionList(res?.list || []);

		setUserDetails((prev) => ({
			...prev,
			username: url.state.username,
			role: url.state.role
		}))

		let ids = []

		url.state.permitted_actions.split(",").forEach(each => {
			ids.push(parseInt(each))
		})

		setAlreadyPermittedIds(ids)
    };

    const handleUserInfo = (e) => {
		setUserDetails(prev => ({
			...prev,
			[e.target.name]: e.target.value
		}))
	};

    const handleCheckBox = (e) => {
        if (e.target.checked) {
			setAlreadyPermittedIds((prev) => [...prev, parseInt(e.target.value)])
        } else {
            setAlreadyPermittedIds((prev) =>
                prev.filter((each) => each !== parseInt(e.target.value))
            );
        }
    };

    const handleRole = (e) => {
        setUserDetails(prev => ({
            ...prev,
            role: parseInt(e.target.value)
        }))
    }

    const handleUpdate = async () => {
        if(userDetails.username === ""){
            clearNotifications()
            return showNotification("error", "Username Field is required")
        }
        
        if(userDetails.password === ""){
            clearNotifications()
            return showNotification("error", "Password Field is required")
        }

		const user = getFromStorage("dnr_a")
		const payload = {
			...userDetails,
			permitted: alreadyPermittedIds.join(","),
			modified_by: user.id,
			id: url.state.id
		}

		const res = await api.userAccountUpdate(payload)

		if(res.flag === 'SUCCESS'){
            clearNotifications()
			showNotification("success", res.msg + " Redirecting...")

			setTimeout(() => {
				navigate("/admin/user/create")
			}, 3000);
		} else {
            clearNotifications()
			showNotification("error", res.msg)
		}
    };

    return (
        <>
            <Navbar />
			<NotificationContainer />

            <div className="usercreate__container">
                <div>User Create</div>
                <div className="usercreate__input-group">
                    <input
                        type="text"
                        autoComplete="off"
                        placeholder="Username"
                        name="username"
						value={userDetails.username}
                        onChange={handleUserInfo}
                    />
                    <input
                        type="text"
                        autoComplete="off"
                        placeholder="Password"
                        name="password"
                        onChange={handleUserInfo}
                    />
                    <select name="role" id="role" value={userDetails.role} onChange={handleRole}>
                        <option value="0">Select Role</option>
                        <option value="3">Reporter</option>
                        <option value="2">Editor</option>
                        <option value="1">Admin</option>
                    </select>
                </div>
                <div className="usercreate__checkbox">
                    {permissionList.map((each) => (
                        <div key={each.permission_name}>
                            <input
                                type="checkbox"
                                id={each.id}
                                value={each.id}
                                onChange={handleCheckBox}
								checked={alreadyPermittedIds.includes(each.id) ? true : false}
                            />
                            <label className="usercreate__checkbox-label" htmlFor={each.id}>{each.permission_name}</label>
                        </div>
                    ))}
                </div>
                <div>
                    <button className="usercreate__btn usercreate__btn-update" onClick={handleUpdate}>Update</button>
                    <button className="usercreate__btn usercreate__btn-cancel" onClick={() => navigate("/admin/user/create")}>Cancel & Go Back</button>
                </div>
            </div>
        </>
    );
}	  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  		  