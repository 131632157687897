import React, { useEffect, useState } from "react";
import { useRef } from "react";
import * as api from "../../../api/ApiRequest"
import moment from "moment-timezone"
import { showNotification } from "../../utility/showNotification";
import { clearNotifications } from "../../utility/clearNotifications";
import { getFromStorage } from "../../utility/Storage";
import { checkPermission } from "../../utility/checkPermission";

export default function MediaRow({ row, toggleMediaCreated }) {
    const [editable, setEditable] = useState(false);
    const [selectedMediaType, setSelectedMediaType] = useState(row.media_type)
    const mediaName = useRef();
    const oldMediaName = (row?.media_name || "")
    const mediaTypes = [
        { label: 'Local', value: 1 },
        { label: 'Foreign', value: 2 }
    ]

    const getUpdatedAt = (val) => {
        if(val === 0){
            return "-"
        } else {
            return moment(val * 1000).tz("Asia/Dhaka").format("DD-MMM-YY, hh:mm a")
        }
    }

    const getUpdateBy = (val) => {
        if(val){
            return val
        } else {
            return "-"
        }
    }

    const toggleUpdate = () => {
        setEditable((prev) => !prev);
        clearNotifications()
    };
    
	const handleUpdate = async () => {
        if((mediaName.current.value === oldMediaName) &&
            (row.media_type === parseInt(selectedMediaType))
        ){
            showNotification("info", "No Change Found")
            return mediaName.current.focus()
        }

        const user = getFromStorage("dnr_a")
		const payload = {
			id: row.id,
			update_value: mediaName.current.value.trim(),
            media_type: selectedMediaType,
			update_at: moment().tz("Asia/Dhaka").unix(),
			update_by: user.id
		}

		const res = await api.updateMediaName(payload)

		if(res.flag === 'SUCCESS'){
			setEditable((prev) => !prev);
            clearNotifications()
			showNotification("success", res.msg)
			toggleMediaCreated()
		} else {
			showNotification("error", res.msg)
		}

	}

    const handleToggleStatus = async () => {
        const updatedStatus = {
            0: 1,
            1: 0
        }
        
        const user = getFromStorage("dnr_a")
		const payload = {
			id: row.id,
			status: updatedStatus[row.status],
			update_by: user.id
		}

        const res = await api.changeMediaStatus(payload)

        if(res.flag === 'SUCCESS'){
            clearNotifications()
            showNotification("success", res.msg)
            toggleMediaCreated()
        } else {
            showNotification("error", res.msg)
        }
    }

    useEffect(() => {
        if(editable){
            mediaName.current.focus()
        }
    }, 
    // eslint-disable-next-line
    [editable])
    
    const getMediaType = (type) => {
        return mediaTypes.find(each => each.value === type)?.label
    }

    return (
        <tr className={`${row.status ? "" : "media-disable"}`}>
            {editable ? (
                <td>
                    <input
                        type="text"
                        defaultValue={row.media_name}
                        ref={mediaName}
                        className="media__edit"
                    />
                </td>
            ) : (
                <td className={`media__table-media ${row.status ? "" : "media-disable"}`}>{row.media_name} ({row.news_count})</td>
            )}
            <td className="text-center">
                {editable
                ?
                <select onChange={(e) => setSelectedMediaType(e.target.value)}>
                    {mediaTypes?.map(each => (
                        each.value === selectedMediaType
                            ? <option key={each.value} value={each.value} selected>{ each.label }</option>
                            : <option key={each.value} value={each.value}>{ each.label }</option>
                    ))}
                </select>
                : getMediaType(row.media_type)
                }
            </td>
            <td className="text-center">{row.created_by}</td>
            <td className="text-center">{moment(row.created_at * 1000).tz("Asia/Dhaka").format("DD-MMM-YY, hh:mm a")}</td>
            <td className="text-center">{getUpdateBy(row.update_by)}</td>
            <td className="text-center">{getUpdatedAt(row.update_at)}</td>
            <td className="text-center">
                {editable ? (
                    <>
                        <button className="media__btn media__btn-update" onClick={handleUpdate}>Update</button>
                        <button className="media__btn media__btn-cancel" onClick={toggleUpdate}>
                            Cancel
                        </button>
                    </>
                ) : (
                    <>
                        <button className="media__btn media__btn-edit" onClick={toggleUpdate}>
                            Edit
                        </button>
                        
                        {checkPermission("media-deactivation") && (
                            <button className={`media__btn ${row.status ? "media__btn-inactive" : "media__btn-active"}`} onClick={handleToggleStatus}>
                                { row.status ? "Deactivate" : "Activate" }
                            </button>
                        )}
                    </>
                )}
            </td>
        </tr>
    );
}
