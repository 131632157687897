import React from "react";
import { useNavigate } from "react-router-dom";
import { checkPermission } from "../../utility/checkPermission";
import Navbar from "../admin-navbar/Navbar";
import "./admindashboard.css";

export default function AdminDashboard() {
    const navigate = useNavigate();

    return (
        <>
            <Navbar />

            <div className="dashboard__wrapper">
                <div className="dashboard__container">
                    <h2 className="dashboard__title">
                        DNR - Dhaka Newsroom | Admin Dashboard
                    </h2>
                    <div className="dashboard__btn-group">
                        {checkPermission("publish-news") && (
                            <button
                                className="dashboard__btns dashboard__btn-cn"
                                onClick={() => navigate("/admin/news/create")}
                            >
                                Publish News
                            </button>
                        )}

                        {checkPermission("news-list") && (
                            <button
                                className="dashboard__btns dashboard__btn-nl"
                                onClick={() => navigate("/admin/news/list")}
                            >
                                News List
                            </button>
                        )}

                        {checkPermission("create-media") && (
                            <button
                                className="dashboard__btns dashboard__btn-cm"
                                onClick={() => navigate("/admin/media/create")}
                            >
                                Create Media
                            </button>
                        )}

                        {checkPermission("scroll-publish") && (
                            <button
                                className="dashboard__btns dashboard__btn-cm"
                                onClick={() => navigate("/admin/news/scroll")}
                            >
                                Scroll Publish
                            </button>
                        )}

                        {checkPermission("create-user") && (
                            <button
                                className="dashboard__btns"
                                onClick={() => navigate("/admin/user/create")}
                            >
                                Create User
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
