import React, { forwardRef, useEffect, useState } from "react";
import moment from "moment-timezone";
import { useRef } from "react";
import { encryptData } from "../utility/encrypt";

const EachNews = forwardRef(({
    eachNews,
    readMoreState,
    handleReadMoreState,
    index,
    newCheckedTimes,
}, ref) => {
    const newsDescRef = useRef();
    const [showReadMoreBtn, setShowReadMoreBtn] = useState(false);

    useEffect(
        () => {
            setShowReadMoreBtn(
                newsDescRef.current.scrollHeight >
                    newsDescRef.current.clientHeight
            );
        },
        // eslint-disable-next-line
        []
    );

    const publishTime = (time) => {
        if (
            moment()
                .tz("Asia/Dhaka")
                .diff(moment(time * 1000).tz("Asia/Dhaka"), "hour") > 0
        ) {
            return moment(time * 1000)
                    .tz("Asia/Dhaka")
                    .format("hh:mm a");
        } else {
            return moment(time * 1000)
                    .tz("Asia/Dhaka")
                    .fromNow();
        }
    };

    const handleShare = () => {
        let url = encryptData(eachNews.id)
        url = url.split("/").join(".")
        url = `/news/share/${url}`

        window.open(url, '_blank', 'noopener,noreferrer')
    }

    return (
        <>
            <div className="news__card" ref={ref}>
                <div hidden>{newCheckedTimes}</div>
                <div className="news__card-topbar">
                    <div className="news__card-time">
                        {publishTime(eachNews?.publish_time)}
                    </div>
                    <div className="news__card-date">
                        {moment
                            .unix(eachNews?.publish_date)
                            .tz("Asia/Dhaka")
                            .format("DD-MMMM-YYYY")}
                    </div>
                </div>
                <div className="news__card-title">{eachNews?.news_title}</div>
                <div
                    className={`news__card-subtitle ${
                        readMoreState[index] ? "expand" : "collapse"
                    }`}
                    ref={newsDescRef}
                    onClick={() => handleReadMoreState(index)}
                >
                    {eachNews?.news_details}
                </div>
                {showReadMoreBtn && (
                    <div
                        className="news__card-more"
                        onClick={() => handleReadMoreState(index)}
                    >
                        {readMoreState[index] ? "Read Less" : "Read More"}
                    </div>
                )}
                <div className="news__card-date_container">
                    <div className="news__card-footer">
                        {eachNews?.news_link
                        ?
                        <a href={eachNews?.news_link} target="_blank" rel="noreferrer" className="news__card-source">{ eachNews?.source }↗</a>
                        :
                        <div className="news__card-source">
                            {eachNews?.source}
                        </div>
                        }
                        <button className="news__card-btn-share" onClick={handleShare}>Share</button>
                    </div>
                </div>
            </div>
            <hr />
        </>
    );
})

export default EachNews;