import React, { useState } from "react";
import "./adminlogin.css"
import * as api from "../../../api/ApiRequest"
import NotificationContainer from "../../utility/NotificationContainer";
import { showNotification } from "../../utility/showNotification";
import { useNavigate } from "react-router-dom";
import { saveOnStorage } from "../../utility/Storage";

export default function AdminLogin() {
	const [username, setUsername] = useState("")
	const [password, setPassword] = useState("")

    const navigate = useNavigate()

	const handleLogin = async (e) => {
        e.preventDefault()

        const payload = { username, password }
        const res = await api.userLogin(payload)

        if(res?.flag !== "SUCCESS"){
            return showNotification("error", res?.msg)
        }
        
        const data = {
            auth: res.token,
            username: res.username,
            id: res.id,
            permitted: res.permitted
        }
        
        saveOnStorage("dnr_a", data)

        const permission = await api.fetchPermissionList()
        const permissionList = {}
        permission?.list?.forEach(each => {
            const key = each.permission_name.toLowerCase().split(" ").join("-")
            permissionList[key] = each.id
        })

        saveOnStorage("dnr_p", permissionList)
        navigate("/admin/dashboard")
    }

    return (
        <div className="login__container">
            <div className="login__box">
                <NotificationContainer />

                <h2 className="login__title">Admin Login</h2>

                <form className="login__form">
                    <input
                        className="login__input"
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <input
                        className="login__input"
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />

                    <button
                        type="submit"
                        className="login__button"
                        onClick={handleLogin}
                    >
                        Login
                    </button>
                </form>
            </div>
        </div>
    );
}
