import React from "react";
import moment from "moment-timezone"
import { useNavigate } from "react-router-dom";
import * as api from "../../../api/ApiRequest"
import { getFromStorage } from "../../utility/Storage";
import { showNotification } from "../../utility/showNotification";
import { clearNotifications } from "../../utility/clearNotifications";
import { checkPermission } from "../../utility/checkPermission";

export default function EachNewsRow({ row, searchedDate, updateNewsList }) {
	const navigate = useNavigate()

    const getPublishDateTime = (val) => {
        return moment(val * 1000).tz("Asia/Dhaka").format("DD-MMM-YY, hh:mm a");
		// return (
		// 	<>
		// 		<div>{moment(val * 1000).tz("Asia/Dhaka").format("DD-MMM-YYYY,")}</div>
		// 		<div>{moment(val * 1000).tz("Asia/Dhaka").format("hh:mm a")}</div>
		// 	</>
		// )
    };

	const getModifiedAt = (val) => {
		if(val === 0){
			return "-"
		} else {
			return moment(val * 1000).tz("Asia/Dhaka").format("DD-MMM-YY, hh:mm a")
		}
	}

	const getModifiedBy = (val) => {
		if(val === null){
			return "-"
		} else {
			return val
		}
	}

	const handleStatusChange = async () => {
		const updatedStatus = {
			0: 1,
			1: 0
		}

		const user = getFromStorage("dnr_a")
		const payload = {
			id: row.id,
			status: updatedStatus[row.status],
			update_by: user.id
		}
		
		const res = await api.changeNewsStatus(payload)

		clearNotifications()

		if(res.flag === 'SUCCESS'){
			showNotification("success", res.msg)
			updateNewsList()
		} else {
			showNotification("error", res.msg)
		}
	}

    return (
		<tr className={`${row.status ? "" : "news-disable"}`}>
            <td style={{ paddingLeft: "0.5rem" }}>{row.news_title}</td>
            <td className="news_desc_row">{row.news_details}</td>
            <td className="text-center">{row.source}</td>
            <td className="text-center">{getPublishDateTime(row.publish_time)}</td>
            <td className="text-center">{row.publish_by}</td>
            <td className="text-center">{getModifiedAt(row.update_at)}</td>
            <td className="text-center">{getModifiedBy(row.update_by)}</td>
            <td className="text-center">
				<div className="news__btn-group">
					<button className="news__btn news__btn-edit" onClick={() => navigate(`/admin/news/edit/${row.id}`, { state: searchedDate })}>Edit</button>
					{checkPermission("news-deactivation") && (
						<button className="news__btn news__btn-edit" onClick={handleStatusChange}>{ row.status ? "Deactivate" : "Activate" }</button>
					)}
				</div>
			</td>
        </tr>
    );
}
