import React, { useEffect, useRef, useState } from "react";
import moment from "moment-timezone";
import * as api from "../../../api/ApiRequest";
import NotificationContainer from "../../utility/NotificationContainer";
import { showNotification } from "../../utility/showNotification";
import MediaRow from "./MediaRow";
import Navbar from "../admin-navbar/Navbar";
import "./media.css";
import { getFromStorage } from "../../utility/Storage";

export default function CreateMedia() {
    const mediaName = useRef();
    const [mediaList, setMediaList] = useState([]);
    const [needUpdate, setNeedUpdate] = useState(false);
    const [searchMode, setSearchMode] = useState(false);
    const [pageNo, setPageNo] = useState(1);

    useEffect(
        () => {
            fetchData();
            setNeedUpdate(false);
        },
        // eslint-disable-next-line
        [needUpdate, pageNo]
    );

    const fetchData = async () => {
        if(!searchMode){
            const res = await api.fetchMediaList(pageNo);
            setMediaList(res?.list || []);
        }
        else{
            const payload = {
                search: mediaName.current.value.trim(),
                page: pageNo
            }
            const res = await api.searchMedia(payload)
    
            setMediaList(res?.list || []);
        }
    };

    const toggleMediaCreated = () => {
        setNeedUpdate(true);
    };

    const handleAddMedia = async (e) => {
        e.preventDefault();

        const errors = ["", null, undefined];
        if (errors.includes(mediaName.current.value)) {
            return showNotification("error", "Enter Media Name First");
        }

        const user = getFromStorage("dnr_a")
        const payload = {
            media_name: mediaName.current.value.trim(),
            created_at: moment().tz("Asia/Dhaka").unix(),
            created_by: user.id,
        };

        const res = await api.createNewMedia(payload);

        if (res.flag === "SUCCESS") {
            showNotification("success", res.msg);
            mediaName.current.value = "";
            setNeedUpdate(true);
        } else {
            showNotification("error", res.msg);
        }
    };

    const handleSearchMedia = async () => {
        setSearchMode(true)

        const payload = {
            search: mediaName.current.value.trim(),
            page: pageNo
        }
        const res = await api.searchMedia(payload)

        setMediaList(res?.list || []);
        console.log(res?.list)
    }

    const handleReset = () => {
        window.location.reload()
    }

    const handlePrevPagination = () => {
        setPageNo((prev) => {
            if (prev > 1) {
                return prev - 1;
            } else {
                return prev;
            }
        });
    };

    const handleNextPagination = () => {
        setPageNo((prev) => prev + 1);
    };

    return (
        <>
            <Navbar />

            <NotificationContainer />
            <div className="media__title text-center">
                Create New Media Page
            </div>

            <div className="media__container">
                <div className="media__add">
                    <input
                        type="text"
                        ref={mediaName}
                        placeholder="Enter media name"
                        className="media__add-input"
                    />
                    <button 
                        className="media__add-btn"
                        onClick={handleAddMedia}
                    >
                        Create Media
                    </button>
                    <button 
                        className="media__add-btn" 
                        onClick={handleSearchMedia}
                    >
                        Search Media
                    </button>
                    <button 
                        className="media__add-btn" 
                        onClick={handleReset}
                    >
                        Reset
                    </button>
                </div>

                <div className="media__subtitle">Media List</div>

                <table className="media__table">
                    <thead className="media__table-head">
                        <tr>
                            <th className="media__table-header">Media Name</th>
                            <th className="media__table-header">Media Type</th>
                            <th className="media__table-header">Created By</th>
                            <th className="media__table-header">Created At</th>
                            <th className="media__table-header">Modified By</th>
                            <th className="media__table-header">Modified At</th>
                            <th className="media__table-header">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {mediaList.map((each) => (
                            <MediaRow
                                key={each.media_name}
                                row={each}
                                toggleMediaCreated={toggleMediaCreated}
                            />
                        ))}
                    </tbody>
                </table>
                <div>
                    <button
                        disabled={pageNo <= 1}
                        onClick={handlePrevPagination}
                        className="media__btn media__btn-pagination"
                    >
                        Prev
                    </button>
                    <span className="media__pageno">{pageNo}</span>
                    <button
                        disabled={mediaList.length < 10}
                        onClick={handleNextPagination}
                        className="media__btn media__btn-pagination"
                    >
                        Next
                    </button>
                </div>
            </div>
        </>
    );
}
