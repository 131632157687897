import CryptoJS from "crypto-js";

/**
 * Saving encrypted data on local storage
 * 
 * @param {String} key saving data with this key
 * @param {Object} data passing the data to be encrypted & saved
 */
 export const saveOnStorage = (key, data) => {
    const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        process.env.REACT_APP_ENCRYPTED_KEY
    ).toString();

    localStorage.setItem(key, encryptedData);
};

/**
 * Retrive decrypted data from local storage
 * 
 * @param {String} key accessing the data with this key
 * @returns JSON
 */
export const getFromStorage = (key) => {
    const storageData = localStorage.getItem(key);
    if(storageData &&
        storageData !== null &&
        storageData !== undefined
    ){
        const bytes = CryptoJS.AES.decrypt(
            storageData,
            process.env.REACT_APP_ENCRYPTED_KEY
        );
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))

        return decryptedData
    } else {
        return null
    }
};