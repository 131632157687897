import cryptojs from"crypto-js"

export const encryptData = (data) => {
	const encryptedData = cryptojs.DES.encrypt(
		JSON.stringify(data),
		process.env.REACT_APP_ENCRYPTED_KEY
	).toString()

	return encryptedData
}

export const decryptData = (data) => {
	const errors = ["", null, undefined]
	if(errors.includes(data)){
		return null
	}

	const bytes = cryptojs.DES.decrypt(
		data,
		process.env.REACT_APP_ENCRYPTED_KEY
	)

	const decryptedData = JSON.parse(bytes.toString(cryptojs.enc.Utf8))
	return decryptedData
}