import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import Homepage from './components/Homepage';
import AdminLogin from "./components/admin/admin-login/AdminLogin"
import AdminDashboard from './components/admin/admin-dashboard/AdminDashboard';
import CreateNews from './components/admin/admin-news-create/CreateNews';
import CreateMedia from './components/admin/admin-media/CreateMedia';
import ProtectedRoute from './routes/ProtectedRoute';
import PageNotFound from './components/page-not-found/PageNotFound';
import AlreadyLoggedIn from './routes/AlreadyLoggedIn';
import NewsList from './components/admin/admin-news-list/NewsList';
import AdminNewsEdit from './components/admin/admin-news-edit/AdminNewsEdit';
import AdminUserCreate from './components/admin/admin-user-create/AdminUserCreate';
import CheckRoutePermission from './routes/CheckRoutePermission';
import ShareNews from './components/news/news-share/ShareNews';
import ScrollNews from './components/admin/admin-scroll-news/ScrollNews';
import AdminUserEdit from './components/admin/admin-user-edit/AdminUserEdit';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Homepage />} />
        <Route path='/news/share/:url' element={<ShareNews />} />

        {/* Check if already logged in */}
        <Route path='/admin' element={<AlreadyLoggedIn />}>
          {/* Only show when user is not logged in */}
          <Route path='/admin' element={<AdminLogin />} />
        </Route>

        {/* Check if admin is logged in to proceed further */}
        <Route path='/admin' element={<ProtectedRoute />}>
          <Route 
            path='/admin/dashboard' 
            element={<AdminDashboard />} 
          />
          <Route 
            path='/admin/news/scroll' 
            element={<ScrollNews />} 
          />
          <Route 
            path='/admin/news/create' 
            element={
              <CheckRoutePermission route="publish-news">
                <CreateNews />
              </CheckRoutePermission>
            } 
          />
          <Route 
            path='/admin/media/create' 
            element={
              <CheckRoutePermission route="create-media">
                <CreateMedia />
              </CheckRoutePermission>
            } 
          />
          <Route 
            path='/admin/news/list' 
            element={
              <CheckRoutePermission route="news-list">
                <NewsList />
              </CheckRoutePermission>
            } 
          />
          <Route 
            path='/admin/news/edit/:id' 
            element={<AdminNewsEdit />} 
          />
          <Route 
            path='/admin/user/create' 
            element={
              <CheckRoutePermission route="create-user">
                <AdminUserCreate />
              </CheckRoutePermission>
            } 
          />
          <Route 
            path='/admin/user/edit' 
            element={
              <AdminUserEdit />
            } 
          />
        </Route>
        
        {/* Not Found Page */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
