import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import * as api from "../../../api/ApiRequest";
import moment from "moment-timezone"
import { decryptData } from "../../utility/encrypt";
import { FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon, LinkedinShareButton, LinkedinIcon, TwitterShareButton, TwitterIcon } from "react-share";
// import MetaDataForFB from "../../utility/MetaDataForFB";

export default function ShareNews() {
    const [news, setNews] = useState({});
    const { url } = useParams();

	let location = useLocation();
    let currentUrl = "https://www.dhakanewsroom.com" + location.pathname;
    // let currentUrl = "http://192.168.110.138:3123" + location.pathname;

    useEffect(
        () => {
            fetchData();
        },
        // eslint-disable-next-line
        []
    );

    const fetchData = async () => {
		let id = url.split(".").join("/")
		id = decryptData(id)

        const res = await api.fetchNewsListById(id);
        setNews(res?.news[0] || {});
    };

	const publishTime = (time) => {
        if (
            moment()
                .tz("Asia/Dhaka")
                .diff(moment(time * 1000).tz("Asia/Dhaka"), "hour") > 0
        ) {
            return moment(time * 1000)
                    .tz("Asia/Dhaka")
                    .format("hh:mm a");
        } else {
            return moment(time * 1000)
                    .tz("Asia/Dhaka")
                    .fromNow();
        }
    };

    return (
        <div className="body negative-margin" style={{ overflow: "hidden" }}>
			{/* <MetaDataForFB
				title={news?.news_title}
				image={window.location.origin +
					"/images/dnr-logo-outline.png"}
				description={news?.news_details}
				hashtag="DhakaNewsRoom"
				currentUrl={currentUrl}
			></MetaDataForFB> */}
            <div>
                <div className="sticky-navbar">
                    <header className="header">
                        <div className="header__container">
                            <div>
                                <img
                                    src={
                                        window.location.origin +
                                        "/images/dnr-logo-outline.png"
                                    }
                                    alt="Dhaka Newsroom"
                                    className="header__logo"
                                />
                            </div>
                            <div className="header__date-section">
                                <h2 className="header__title">Breaking News</h2>
                                <div>Latest updates of the world</div>
                            </div>
                        </div>
                    </header>
                </div>

                <div className="container" style={{ marginTop: "6rem" }}>
                    <main className="container__main">
                        <div className="news__card">
                            <div className="news__card-time">
                                {publishTime(news?.publish_time)}
                            </div>
                            <div className="news__card-title">
                                {news?.news_title}
                            </div>
                            <div
                                className={`news__card-subtitle`}
                            >
                                {news?.news_details}
                            </div>
                            <div className="news__card-date_container">
                                <div className="news__card-date">
                                    {moment
                                        .unix(news?.publish_date)
                                        .tz("Asia/Dhaka")
                                        .format("DD-MMMM-YYYY")}
                                </div>
                                <div className="news__card-footer">
                                    <div className="news__card-source">
                                        {news?.source}
                                    </div>
                                    {/* <button
                                        className="news__card-btn-share"
                                    >
                                        Facebook
                                    </button> */}
                                    <div>
									<FacebookShareButton 
                                        // quote={news?.news_title}
                                        quote="Dhaka Newsroom is the hero"
                                        title="Dhaka Newsroom is the hero"
                                        hashtag="#DhakaNewsroom"
										url={currentUrl}
										className="news__card-btn-share">
										<FacebookIcon size={36} />
									</FacebookShareButton>
									<WhatsappShareButton 
                                        title={news?.news_title + "\n\n"}
										url={currentUrl}
										className="news__card-btn-share">
										<WhatsappIcon size={36} />
									</WhatsappShareButton>
									<LinkedinShareButton 
                                        title={news?.news_title + "\n\n"}
										summary={news?.news_details + "\n\n"}
                                        source="DNR | Dhaka Newsroom"
										url={currentUrl}
										className="news__card-btn-share">
										<LinkedinIcon size={36} />
									</LinkedinShareButton>
									<TwitterShareButton 
                                        title={news?.news_title + "\n" + news?.news_details + "\n"}
										via="dhakanewsroom.com"
                                        hashtags={["DhakaNewsroom", "DNR"]}
										url={currentUrl}
										className="news__card-btn-share">
										<TwitterIcon size={36} />
									</TwitterShareButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>

            <div className="footer">
                <div>প্রিয় পাঠক।</div>
                <div>
                    ঢাকা নিউজরুম দেশ-বিদেশের সংবাদ মাধ্যমে প্রকাশিত খবর থেকে
                    শুধুমাত্র ব্রেকিং নিউজসমূহ পরিবেশন করে থাকে। বিভিন্ন মাধ্যম
                    থেকে সন্নিবেশিত খবরসমূহ পাঠকদের জন্য পরিবেশিত হয়েছে বলে এর
                    কোন দায় দায়িত্ব আমাদের উপর বর্তায় না। আপনার মতামত বা যেকোন
                    প্রয়োজনে যোগাযোগ করুন dhakanewsroom@gmail.com.
                </div>
                <div>আমাদের সাথে থাকার জন্য ধন্যবাদ।</div>
                {/* <div>
                    <span>পুরোনো সংখ্যা </span>
                    <input
                        type="date"
                        name="date"
                        id="date"
                        // value={searchDate}
                        // onChange={handleDateSelection}
                    />
                </div> */}
            </div>
        </div>
    );
}
