import React, { useEffect, useState } from "react";
import Navbar from "../admin-navbar/Navbar";
import * as api from "../../../api/ApiRequest";
import NotificationContainer from "../../utility/NotificationContainer";
import { showNotification } from "../../utility/showNotification";
import { clearNotifications } from "../../utility/clearNotifications";
import "./scroll.css";

export default function ScrollNews() {
    const initialCount = 1;
    const [count, setCount] = useState(initialCount);

    const initialBoxState = [{ id: count }];
    const [inputBoxes, setInputBoxes] = useState(initialBoxState);

    const initialState = [{ id: count, text: "" }];
    const [state, setState] = useState(initialState);

    const [scrollList, setScrollList] = useState([]);
    const [reFetchNeeded, setReFetchNeeded] = useState(false);

    useEffect(
        () => {
            fetchData();
            setReFetchNeeded(false);
        },
        // eslint-disable-next-line
        [reFetchNeeded]
    );

    const fetchData = async () => {
        const res = await api.fetchNewsScrollList();
        setScrollList(res?.scrolls || []);
    };

    const handleChange = (e, index) => {
        const update = [...state];
        update[index].text = e.target.value;

        setState(update);
    };

    const handleAddInput = () => {
        setInputBoxes((prev) => [...prev, { id: count + 1 }]);

        setState((prev) => [
            ...prev,
            {
                id: count + 1,
                text: "",
            },
        ]);

        setCount((prev) => prev + 1);
    };

    const handleRemoveInput = async (id) => {
        if(inputBoxes.length === 1){
            return showNotification("error", "You cannot delete all the fields")
        }

        setState((prev) => prev.filter((each) => each.id !== id));
        setInputBoxes((prev) => prev.filter((each) => each.id !== id));
    };

    const handleScrollDeleteFromList = async (id) => {
        const res = await api.deleteNewsScrollById(id);

        if (res?.flag === "SUCCESS") {
            clearNotifications();
            showNotification("success", res?.msg);
            setReFetchNeeded(true);
        } else {
            showNotification("error", res?.msg);
        }
    };

    const handlePublish = async () => {
        const errors = ["", null, undefined];
        const inputs = state.map((each) => errors.includes(each.text));

        if (inputs.some((each) => each === true)) {
            return showNotification("error", "All fields are required!");
        }

        const payload = {
            scrolls: state.map((each) => each.text),
        };
        const res = await api.setNewsScroll(payload);

        if (res?.flag === "SUCCESS") {
            showNotification("success", res?.msg);
            setReFetchNeeded(true);
            setCount(initialCount);
            setInputBoxes(initialBoxState);
            setState(initialState);
        } else {
            showNotification("error", res?.msg);
        }
    };

    return (
        <>
            <Navbar />
            <NotificationContainer />

            <div className="scroll__container">
                <div className="scroll__title">Publish Important Scrolls</div>
                {inputBoxes.map((_, index) => (
                    <div key={index} className="scroll__input-wrapper">
                        <input
                            className="scroll__input"
                            type="text"
                            value={state[index].text}
                            onChange={(e) => handleChange(e, index)}
                        />
                        <button
                            className="scroll__btn scroll__btn-add"
                            onClick={handleAddInput}
                        >
                            Add
                        </button>
                        <button
                            className="scroll__btn scroll__btn-remove"
                            onClick={() => handleRemoveInput(state[index].id)}
                        >
                            Remove
                        </button>
                    </div>
                ))}
                <button
                    className="scroll__btn scroll__btn-publish"
                    onClick={handlePublish}
                >
                    Publish
                </button>
            </div>

            <div className="scroll__container">
                <table style={{ width: "100%" }}>
                    <thead className="scroll__table-head">
                        <tr>
                            <th style={{ width: "90%", fontFamily: "serif" }}>Scroll Headline</th>
                            <th style={{ width: "10%", fontFamily: "serif" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {scrollList.map((each) => (
                            <tr key={each.imp_scroll} className="scroll__table-cell">
                                <td>{each.imp_scroll}</td>
                                <td style={{ textAlign: "center" }}>
                                    <button
                                        onClick={() =>
                                            handleScrollDeleteFromList(each.id)
                                        }
                                    >
                                        Remove
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}
